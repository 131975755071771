/* -------------------------------------------------------------------------- *
 * >>> SITE FOOTER 
 * -------------------------------------------------------------------------- */


.site_footer {
	// position: relative;
	display: flex;
	height: auto;
	box-sizing: border-box;
	border-top: 28px solid #494C4F;
	border-bottom: 5px solid #494C4F;
	margin-bottom: 89px;

    @include tablet {
		height: auto;
    	margin-bottom: 160px;
    }
    @include tablet-lg {
		height: auto;
    	margin-bottom: 160px;
    }
    @include desktop {
		height: 150px;
    	margin-bottom: 259px;
    }


	.container {
		align-items: center;
    	flex-direction: column;
    	padding: 30px 25px;
    	
    	@include tablet {
    		flex-direction: column;
    		padding: 40px 15px;
		}
		@include tablet-lg {
    		flex-direction: row;
    		padding: 0px 15px;
		}
		@include desktop {
    		flex-direction: row;
    		padding: 0px 15px;
		}
    	.footer_logo {        
	        margin-bottom: 30px;

	        img {
	        	cursor: pointer;
	        }

	        @include tablet {
	            margin-bottom: 30px;
	        }
	        @include tablet-lg {
	            margin-right: 40px;
	            margin-bottom: 0px;
	        }
	        @include desktop {
	            margin-right: 125px;
	            margin-bottom: 0px;
	        }
    	}
    	.site_navigation_footer {
    		ul {
    			flex-direction: column;
    			align-items: center;
    			width: 100%;


				li {
					margin-bottom: 20px;

					@include tablet {
						margin-bottom: 20px;
					}
					@include tablet-lg {
						margin-bottom: 0px;
					}
					@include desktop {
						margin-bottom: 0px;					    
					}
				}
    			@include tablet {
    				flex-direction: column;
    				align-items: center;
    				width: 100%;
				}
				@include tablet-lg {
    				flex-direction: row;
    				width: 740px;
				}
				@include desktop {
    				flex-direction: row;				    
    				width: 740px;
				}
    		}
	    	ul li a {
	    		font-family: 'Roboto-Light';
			    font-weight: 300;
			    font-size: 18px;
			    line-height: 21px;
			    text-decoration: none;
			    color: #000000;
			    cursor: pointer;
			}
			.rules_rights {
	    		font-family: 'Roboto-Light';
				font-size: 10px;
				line-height: 12px;
				text-align: center;
				margin-top: 20px;
				.rules a {
			    	text-decoration: none;
					color: #313131;
					cursor: pointer;
				}
			}
    	}
	}
	.bottom {
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		bottom: 0;
		width: 100%;
		background: rgba(255,255,255,0.7);
		padding: 15px 0;
		z-index: 1;

		.attention {
			width: 320px;
			opacity: 0.7;

	        @include tablet {
	        	width: 710px;
	        }
	        @include tablet-lg {
	        	width: 710px;
	        }
	        @include desktop {
				width: 1255px;
	        }
		}
	}
}	