/* -------------------------------------------------------------------------- 
 * >>> SITE HEADER 
 * -------------------------------------------------------------------------- */
.site_header {
    position: relative;
	display: flex;
    height: 80px;
    background: #494C4F;
    z-index: 100;

    a.header_logo {
        
        img {
            cursor: pointer;
            transition: all .5s ease;
            &:hover {
                transform: scale(1.1);
            }
        }
        @include tablet {

        }
        @include tablet-lg {
            margin-right: 40px;
        }
        @include desktop {
            margin-right: 125px;
        }
    }

    .mob_menu_btn {
        display: none;
    }
}
.site_header .container {
    align-items: center;
    flex-direction: row;
}

.menu ul {
    width: 740px;
    display: flex;
    justify-content: space-between;
}


.site_navigation_header {
    display: none;

    @include tablet {
        display: none;
    }
    @include tablet-lg {
        display: block;
    }
    @include desktop {
        display: block;
    }
    ul li a {
        font-family: 'Roboto-Light';
        font-weight: 300;
        font-size: 18px;
        line-height: 21px;
        text-decoration: none;
        color: #FFFFFF;
        cursor: pointer;
    }
}

.open_menu {
    display: block;
    margin-left: auto;

    @include tablet {
        display: block;
    }
    @include tablet-lg {
        display: none;
    }
    @include desktop {
        display: none;
    }
}

.wrapper_menu_mob {
    width: 100%;
    overflow: hidden;
    .menu_mob {
        position: fixed;
        top: 0;
        bottom: 0;
        left: -300px;
        width: 300px;
        box-sizing: border-box;
        padding: 13px;
        background: #ffffff;
        z-index: 999;
        transition: 0.5s all ease-in-out;
        &.active {
            left: 0;
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.50);
            transition: 0.5s all ease-in-out;
        }
        
        .navigation_menu ul li a {
            font-family: 'Roboto-Light';
            font-weight: 300;
            font-size: 18px;
            line-height: 21px;
            text-decoration: none;
            color: #313131;
            text-transform: uppercase;
            cursor: pointer;
        }
        .head_menu {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
        }
        .navigation_menu ul {
            display: flex;
            flex-direction: column;
            padding-top: 30px;
        }
        .navigation_menu ul li {
            margin-bottom: 20px;
        }
        .navigation_menu ul li a {
            &.active {
            }
        }
    }
}












































/* SITE HEADER : Hamurger */
/* -------------------------------------------------------------------------- */

.menu-toggle {
  display: flex;
  align-items: center;
  position: absolute;
  top: 3px;
  left: px(352px, 'xs');
  transform: translateX(-100%);
  cursor: pointer;

  @include md-up {
    top: 34px;
    left: px(748px, 'md');
  }

  @include lg-up {
    left: px(1274px, 'lg');
  }

  @include xl-up {
    top: 32px;
    left: px(1812px, 'xl');
  }
}

.hamburger {
  cursor: pointer;

  @include md-up {
    margin-top: 3px;
  }
}

.menu-toggle-text {
  display: none;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 150%; /* or 27px */
  color: #000000;
  cursor: pointer;

  @include md-up {
    display: block;
    margin-left: 28px;
  }
}

/*!
 * delicious-hamburgers
 * @description Beautiful SASS powered hamburger menu toggle buttons
 * @author Kasper Koman
 * @link https://github.com/kapoko/delicious-hamburgers
 */

// Variables
$hamburger-class-name: 'hamburger' !default;
// $hamburger-size: 50px !default;
$hamburger-size: 30px;
$hamburger-thickness: 2px !default;
$hamburger-color: #000 !default;
$hamburger-color-hover: $hamburger-color !default;
$hamburger-color-active: $hamburger-color !default;
$hamburger-background: transparent !default;
$hamburger-background-active: $hamburger-background !default;
$hamburger-background-hover: $hamburger-background !default;
$hamburger-background-transition-speed: 0.2s !default;
$hamburger-border-color: transparent !default;
$hamburger-border-width: 0 !default;
$hamburger-opacity: 1 !default;
$hamburger-opacity-hover: 1 !default;
$hamburger-opacity-transition-speed: 0.2s !default;
$hamburger-padding: round($hamburger-size / 10) !default;
$hamburger-border-radius: 0 !default;
$hamburger-bar-border-radius: 0 !default;
// $hamburger-bar-spacing: round($hamburger-size / 5) !default;
$hamburger-bar-spacing: 6px;
$hamburger-animation-speed: 1 !default;

// Animation types. Use this as a variable and remove the animations
// you don't need so they won't get compiled in your css.

$hamburger-bar-size: $hamburger-size - ($hamburger-padding * 2) - ($hamburger-border-width * 2);
$hamburger-easing: cubic-bezier(0.645, 0.045, 0.355, 1) !default;
$hamburger-easing-in: cubic-bezier(0.55, 0.055, 0.675, 0.19) !default;
$hamburger-easing-out: cubic-bezier(0.215, 0.61, 0.355, 1) !default;


@mixin hamburger-bar() {
  background-color: $hamburger-color;
  border-radius: $hamburger-bar-border-radius;
  content: '';
  display: block;
  height: $hamburger-thickness;
  position: absolute;
  width: 100%;
}

button.hamburger {
  width: $hamburger-size;
  height: $hamburger-size;
}

.hamburger {
	height: $hamburger-size - ($hamburger-border-width * 2);
	width: $hamburger-size - ($hamburger-border-width * 2);
	// width: 30px - ($hamburger-border-width * 2);
	// height: 12px - ($hamburger-border-width * 2);

	> .inner {
		top: 50%;
	}   
}

.hamburger {
  background-color: $hamburger-background;
  border: $hamburger-border-width $hamburger-border-color solid;
  border-radius: $hamburger-border-radius;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  opacity: $hamburger-opacity;
  overflow: visible;
  // padding: $hamburger-padding;
  padding: 0;
  text-transform: none;
  transition: opacity $hamburger-opacity-transition-speed $hamburger-easing, background $hamburger-background-transition-speed $hamburger-easing;

  &:hover {
    background-color: $hamburger-background-hover;
    opacity: $hamburger-opacity-hover;

    span.bar {
      background-color: $hamburger-color-hover;
    } 

    > .inner,
      span.bar {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {

        &::before,
        &::after {
          background-color: $hamburger-color-hover;
        }

			  &:hover {
			  	cursor: pointer;
			  }
      }
    }
  }

  &.active {
		background-color: $hamburger-background-active;

		span.bar {
      background-color: $hamburger-color-active;
      border-radius: 50%;
    }

    > .inner,
    span.bar {
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {

        &::before,
        &::after {
          background-color: $hamburger-color-active;
        }       
      }
    }
  }
    
  > .inner {
    margin: 0;
    margin-top: -$hamburger-thickness / 2;
    position: relative;
    width: 100%;
  }

  span.bar {
    @include hamburger-bar();
    transition: transform 0.3s / $hamburger-animation-speed $hamburger-easing 0.2s / $hamburger-animation-speed,
                opacity 0.25s / $hamburger-animation-speed $hamburger-easing 0.25s / $hamburger-animation-speed,;
    &:nth-child(1) {
      top: -$hamburger-bar-spacing;
      transform: translate3d(0, -$hamburger-bar-spacing, 0);
    }
                &:nth-child(2) {
                    opacity: 0;
                }

    &:nth-child(3) {
      width: 100%;
      top: $hamburger-bar-spacing;
      right: 0;
                      transform: translate3d(0, $hamburger-bar-spacing, 0);
    }

    &:nth-child(1),
    &:nth-child(3) {
      background: none;
      top: 0;
      // transition: width $hamburger-opacity-transition-speed ease-in-out;

      &::before {
        @include hamburger-bar();
        transition: transform 0.35s / $hamburger-animation-speed $hamburger-easing;
        }
    }
  }
    
  &:focus {
    outline: 0;
  }
  
  &.active {
  
    span.bar {
      transition: transform 0.25s / $hamburger-animation-speed $hamburger-easing, 
                  opacity 0.25s / $hamburger-animation-speed $hamburger-easing;

      &:nth-child(1),
      &:nth-child(3) {
        transform: translate3d(0, 0, 0);

        &::before {
          transition: transform 0.35s / $hamburger-animation-speed $hamburger-easing 0.15s / $hamburger-animation-speed;
        }
      }

      &:nth-child(1) {

        &::before {
          transform: rotate(45deg);
        }
      }

      &:nth-child(2) {
        opacity: 0;
      } 

      &:nth-child(3) {
        width: 100%;

        &::before {
          transform: rotate(-45deg);
        }
      }
    }
  }
}
