/* -------------------------------------------------------------------------- 
 * >>> PAGE : TEST 
 * -------------------------------------------------------------------------- */

.section-test {
  // overflow: hidden;
}

.section-test-inner {
  width: 100%;
  // max-width: 1440px;
  position: relative;
  margin: auto;
  overflow-x: hidden; 

  &:before {
    // width: 175px;
    // height: 405px;
    width: 12.153%;
    height: 43.47%;
    display: block;
    position: absolute;
    // top: 390px;
    // left: 70px;
    top: 50%;
    left: 5%;
    background-image: url('../images/test-page-decor-01.svg');
    background-size: contain;
    background-repeat: no-repeat;

    // border: 1px dotted #000000;

    @include laptop {
      content: '';
    }
  }

  &:after {
    // width: 323px;
    // height: 615px;
    width: 22.699%;
    // height: 68.64%;
    height: 95%;
    display: block;
    position: absolute;
    // top: 120px;
    // right: -75px;
    // top: 25%;
    top: 5%;
    right: 0;
    background-image: url('../images/test-page-decor-02.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;

    // border: 1px dotted #000000;

    @include laptop {
      content: '';
    }
  }
}

// .section-test--decor-left {
//   position: absolute;
//   top: 390px;
//   left: 70px;
// }

// .section-test--decor-right {
//   position: absolute;
//   top: 20px;
//   right: 0px;
// }

.test-header {
  text-align: center;
  // position: absolute;
  top: 0px;
  // left: 50%;
  // transform: translateX(-50%);
  padding-top: 50px;
  z-index: 10;

  @include desktop {
    padding-top: 80px;
  }
}

.test-block {
  width: 100%;
  max-width: 750px;
  margin: auto;
  // border: 1px dotted black;
  box-sizing: border-box;
  position: relative;

  @include tablet {
    padding-bottom: 0px;
    margin-bottom: 50px;
  }

  @include laptop {
    padding-bottom: 170px;
    margin-bottom: 100px;
  }

  @include desktop {
    padding-bottom: 170px;
  }
}

.test-content {
  width: 100%;
  // height: 512px;
  // border: 1px solid red;
  // position: relative;
  position: absolute;
  top: 200px;
  z-index: -1;

  @include tablet {
    top: 165px;
  }

  @include laptop {
    top: 135px;
    // height: 80vh;
  }
}

.test-title {
  font-family: 'Roboto-Medium';
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #313131;
  margin-bottom: 10px;

  @include tablet {
    font-size: 38px;
    line-height: 45px;
  }

  @include laptop {
    font-size: 26px;
    line-height: 23px;
  }

  @include desktop {
    font-size: 38px;
    line-height: 45px;
  }
}

.test-subtitle {
  font-family: 'Roboto-Light';
  font-size: 14px;
  line-height: 16px;
  color: #7D7D7D;

  @include tablet {
    font-size: 24px;
    line-height: 28px;
  }

  @include laptop {
    font-size: 20px;
  }

  @include desktop {
    font-size: 24px;
    line-height: 28px;
  }
}


.test-shape {
  width: 100%;
  max-width: 320px;
  // height: 896px;
  // max-width: 60%;
  // height: 100%;

  margin: 0 auto; // -125px auto 0;
  // border: 1px solid #cfcfcf;
  // position: relative;

  @include laptop {
    max-width: 1226px;
  }

  &.true {

    .ray-large path,
    .ray-small path {
      fill: url(#paint_true);
    }
  }


  &.false {

    .ray-large path,
    .ray-small path {
      fill: url(#paint_false);
    }
  }

  // .vector0 {
  //   display: block;
  //   width: 100%;
  //   max-width: 843px;
  //   position: absolute;
  //   bottom: 0;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   margin: auto;
  // }

  .ray-large {
    // max-width: 1266px;
    max-width: 430px;
    height: auto;
    position: absolute;
    // bottom: 80px;
    bottom: -230px;
    left: 50%;
    transform: translateX(-50%);
    opacity: .5;

    @include tablet {
      max-width: 680px;
      bottom: -350px;
    }

    @include laptop {
      max-width: 565px;
      bottom: -297px;
    }

    @include desktop {
      max-width: 910px;
      bottom: -480px;
    }

    @include retina {
      max-width: 915px;
      bottom: -480px;
    }
  }

  .ray-small {
    max-width: 225px;
    bottom: -206px;
    height: auto;
    position: absolute;
    // bottom: 145px;
    left: 50%;
    transform: translateX(-50%);

    @include tablet {
      max-width: 360px;
      bottom: -315px;
    }

    @include laptop {
      max-width: 295px;
      bottom: -267px;
    }

    @include desktop {
      max-width: 475px;
      bottom: -430px;
    }
  }
}

.test-question {
  min-height: 400px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: center;
  font-size: x-large;
  padding-top: 36px;
  position: relative;
  // margin-top: 50px;

  @include laptop {
    padding-top: 16px;
  }

  @include desktop {
    padding-top: 52px;
  }

  p {
    width: 100%;
    max-width: 415px;
    font-family: 'Roboto-Regular';
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #494C4F;
    margin-bottom: 35px;

    @include desktop {
      font-size: 20px;
      line-height: 23px;
    }
  }
}

.test-btn-group {
  width: 210px;
  display: flex;
  justify-content: space-between;
  margin: auto;

}

.test-btn {
  width: 72px;
  height: 40px;

  border: 2px solid #000000;
  box-sizing: border-box;
  border-radius: 5px;

  font-family: 'Roboto-Medium';
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;

  background-color: #ffffff;
  box-shadow: 0px 2px 4px rgba(163, 41, 95, .45);

  &:hover {
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
  }
}

.test-button-next {
  font-family: 'Roboto-Light';
  font-size: 14px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  border: 0px;
  background: transparent;
  box-sizing: border-box;
  cursor: pointer;


  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 40px;
  background: rgb(255, 255, 255);
  border: 2px solid rgb(0, 0, 0);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0px 25px;
  font-family: Roboto-Medium;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
}


.test-shape-circle {
  width: 100%;
  max-width: 550px;
  height: auto;
  // left: 0;
  // bottom: 0;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0) rotateX(60deg);
  position: absolute;

  @include laptop {
    max-width: 465px;
    transform: translate(-50%, 0) rotateX(60deg);
  }

  @include desktop {
    max-width: 845px;
    transform: translate(-50%, 0) rotateX(60deg);
  }

  #shape-circle-parts {
    animation: rotate 13.8s ease infinite;
    transform-origin: center;
  }

  #shape-circle-rings {

  }

  .dial-inner {
    animation: rotate 13.8s ease infinite;
    transform-origin: center;
  }

  .dial-out {
    animation: rotate 12.8s ease-in-out alternate infinite;
    transform-origin: center;
  }

  .wide-circles {
    animation: rotate 9.8s ease-in-out infinite;
    transform-origin: center;
  }
}




.hidden,
.site_footer {
  // display: none!important;
}


.test#test {

  .test_tabs {
    position: relative;
    width: 100%;
    height: 400px;
    // background: #000;

    .tabs_content {
      display: none;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-top: 30px;

      &.active {
        display: flex;
      }

      .question {
        max-width: 415px;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        color: #494C4F;
        margin-bottom: 30px;
      }

      .buttons {
        display: flex;

        .test_btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 70px;
          height: 40px;
          border: 2px solid #000000;
          box-sizing: border-box;
          border-radius: 5px;
          font-family: 'Roboto-Medium';
          font-size: 20px;
          line-height: 23px;
          text-align: center;
          text-transform: uppercase;
          cursor: pointer;
        }
      }
    }

    .circles {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;
      transform: rotateX(60deg);


      @include tablet {
        overflow: visible;
      }

      @include tablet-lg {
        overflow: visible;
      }

      @include desktop {
        overflow: visible;
      }

      .test_circle {
        position: absolute;
        width: 100%;

        @include tablet {
          width: unset;
        }
          
        @include tablet-lg {
          width: unset;
        }

        @include desktop {
          width: unset;
        }
      }                

      .circle1 {
        // animation: rotate 9.8s ease-in-out infinite;
      }

      .circle2 {
        animation: rotate 12.8s ease-in-out alternate infinite;
      }

      .circle3 {
        animation: rotate 14.8s linear alternate infinite;
      }

      .circle4 {
        animation: rotate 10.8s ease-in-out alternate infinite;
      }

      .circle5 {
        animation: rotate 13.8s ease infinite;
      }

      .vector {
        position: absolute;
      }

      .vector1 {
        top: 70px;
      }

      .vector2 {
        top: -50px;
      }
    }
  }
}









.content {

  margin: 0 auto;

}

#quiz {

  // display: flex;

  // flex-direction: column-reverse;

  // justify-content: center;

  // align-items: center;

  // position: relative;

  // // margin-top: 50px;

  // font-size: x-large;

}

// #quiz p {

//   max-width: 415px;

//   font-family: 'Roboto-Regular';

//   font-size: 20px;

//   line-height: 23px;

//   text-align: center;

//   color: #494C4F;

// }

#quiz ul {

  display: flex;

  justify-content: center;

  align-items: center;

  list-style-type: none;

  padding: 0;

  margin: 20px 0;

  font-size: smaller;

}

#quiz ul li {

  position: relative;

  display: flex;

  justify-content: center;

  align-items: center;

  width: 70px;

  height: 40px;

  background: #fff;

  border: 2px solid #000000;

  box-sizing: border-box;

  border-radius: 5px;

  margin: 0 25px;

  font-family: 'Roboto-Medium';

  font-size: 20px;

  line-height: 23px;

  text-align: center;

  text-transform: uppercase;

  cursor: pointer;    

}

#quiz ul li label {

  position: absolute;

  top: 0;

  bottom: 0;

  left: 0;

  right: 0;

  cursor: pointer;

}

#quiz ul li label.active {

  background: red;

}

input[type="radio"] {
  display: block;
  width: 1px;
  height: 1px;
  overflow: hidden;
  opacity: 0;
}

// #next, #start {

//   /*width: 70px;*/

//   height: 40px;

//   background: #fff;

//   border: 2px solid #000000;

//   box-sizing: border-box;

//   border-radius: 5px;

//   font-family: 'Roboto-Medium';

//   font-size: 20px;

//   line-height: 23px;

//   text-align: center;

//   text-transform: uppercase;

//   cursor: pointer;

// }

#prev {

  display:none;

}



#start {

  display:none;

  width: 90px;

}



#warning {

  font-family: 'Roboto-Medium';

  font-size: 20px;

  line-height: 23px;

  text-align: center;    

  color: #494C4F;

}



@media(max-width: 767px) {

  #start {

    margin-left: 25px;

  }

}
