/* FONT FACE */
/* -------------------------------------------------------------------------- */

@mixin font-face($font-family, $file-path, $weight: normal, $style: normal, $asset-pipeline: false, $unicode-range: false) {

  $file-path: '../fonts/#{$file-path}'; // DEV
  // $file-path: '../ergo/assets/fonts/#{$file-path}'; // PROD

  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    // font-display: swap;
    font-display: block;

    @if $asset-pipeline == true {
      src: font-url('#{$file-path}.eot'); /* IE9 Compat Modes */
      src: font-url('#{$file-path}.eot?#iefix')          format('embedded-opentype'),
           font-url('#{$file-path}.woff')                format('woff'),
           font-url('#{$file-path}.woff2')               format('woff2'),
           font-url('#{$file-path}.ttf')                 format('truetype'),
           font-url('#{$file-path}.svg##{$font-family}') format('svg');
    } @else {
      src: url('#{$file-path}.eot');
      src: url('#{$file-path}.eot?#iefix')               format('embedded-opentype'),
           url('#{$file-path}.woff')                     format('woff'),
           url('#{$file-path}.woff2')                    format('woff2'),
           url('#{$file-path}.ttf')                      format('truetype'),
           url('#{$file-path}.svg##{$font-family}')      format('svg');
    }

    @if $unicode-range == true {
      unicode-range: $unicode-range;
    }
  }
}

// FONT mixin
// -------------------------------------------------------------------------- */

@mixin font($family: false, $size: false, $colour: false, $weight: false, $lh: false) {

  @if $family { font-family: $family; }
  @if $size { font-size: $size; }
  @if $colour { color: $colour; }
  @if $weight { font-weight: $weight; }
  @if $lh { line-height: $lh; }
}


/* PLACEHOLDER mixin */
/* -------------------------------------------------------------------------- */

@mixin placeholder {
  // &.placeholder { @content; }
  &:-moz-placeholder { @content; } /* Firefox 18- */
  &::-moz-placeholder { @content; } /* Firefox 19+ */
  &::-webkit-input-placeholder { @content; }
  &:-ms-input-placeholder { @content; }
}
