/* -------------------------------------------------------------------------- 
 * >>> PAGE : instruction 
 * -------------------------------------------------------------------------- */

.instructions .name {	
	font-family: 'Roboto-Medium';
	font-size: 20px;
	line-height: 23px;
	text-transform: uppercase;
	color: #000000;
	margin-bottom: 20px;
}
.instructions {
	margin-bottom: 70px;
	.container {
		.heading {
			margin-bottom: 45px;
		}
		.instruction_top {
			display: flex;
			justify-content: space-between;
		    flex-direction: column;
			margin-bottom: 28px;

			@include tablet {
		    	flex-direction: column;
		    }
		    @include tablet-lg {
		    	flex-direction: column;
		    }
		    @include desktop {
		    	flex-direction: row;
			}
			.instruction_top_item {
				width: 100%;
			    background: #FFFFFF;
				padding: 30px 35px;
				border: 5px solid #FF87BD;
				border-radius: 10px;
				box-sizing: border-box;
			    margin-bottom: 25px;
			    transition: all .5s ease;

			    &:hover {
			    	transform: scale(1.1);
			    }

				@include tablet {
			    	width: 100%;
			    	margin-bottom: 25px;
			    }
			    @include tablet-lg {
			    	width: 100%;
			    	margin-bottom: 25px;
			    }
			    @include desktop {
			    	width: 48.7%;
				}

				.item_head {
					display: flex;
					align-items: center;
					height: 60px;
					margin-bottom: 25px;

					.name {
						margin-left: 20px;
					}
				}
			}
		}
		.instruction_main {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			.instruction_main_item {
			    width: 100%;
			    background: #FFFFFF;
				padding: 38px 35px 25px;
				border: 1px solid #FF87BD;
				border-radius: 10px;
				box-sizing: border-box;
				margin-bottom: 28px;
			    transition: all .5s ease;

			    &:hover {
			    	transform: scale(1.1);
			    }

				@include tablet {
			    	width: 100%;
			    }
			    @include tablet-lg {
			    	width: 100%;
			    }
			    @include desktop {
			    	width: 390px;
				}
			}
			.instruction_main_item.first {
				width: 100%;

				@include tablet {
			    }
			    @include tablet-lg {
			    }
			    @include desktop {
				}
			}
		}
		.instruction_bottom {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-bottom: 50px;

			.instruction_bottom_item.last {
				width: 100%;

				@include tablet {
			    }
			    @include tablet-lg {
			    }
			    @include desktop {
				}
			}
			.instruction_bottom_item {
				width: 100%;
				background: #FFFFFF;
				padding: 38px 35px 25px;
				border: 1px solid #FF87BD;
				border-radius: 10px;
				box-sizing: border-box;
				margin-bottom: 28px;
			    transition: all .5s ease;

			    &:hover {
			    	transform: scale(1.1);
			    }
				
				@include tablet {
			    	width: 100%;
			    }
			    @include tablet-lg {
			    	width: 100%;
			    }
			    @include desktop {
			    	width: 390px;
				}
			}
			.group_bott {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;				
				width: 100%;
				
				@include tablet {
			    	width: 100%;
			    }
			    @include tablet-lg {
			    	width: 100%;
			    }
			    @include desktop {
			    	width: 793px;
				}
			}
		}
		.btn {
			margin: 0 auto;
		}
	}
}