/* -------------------------------------------------------------------------- 
 * >>> PAGE : about 
 * -------------------------------------------------------------------------- */

.first_about {
	margin-bottom: 90px;
	.container{
		.heading {
			margin-bottom: 55px;
		}

		.first_about_content {
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			margin-bottom: 45px;

			@include tablet {
				flex-direction: column;
			}
            @include tablet-lg {
				flex-direction: row;
            }
            @include desktop {
				flex-direction: row;
            }

			.left {
				display: flex;
				align-items: center;
				justify-content: center;
				// width: 45%;
				img {					
					transition: all .5s ease;
					width: 100%;

					@include tablet {
						width: unset;
					}
		            @include tablet-lg {
						width: unset;
		            }
		            @include desktop {
						width: unset;
		            }
				}
				img:hover {
					transform: scale(1.15);
				}
			}
			.right {
				width: unset;
				margin: 0 auto;				

				@include tablet {
					width: 385px;
				}
	            @include tablet-lg {
					width: 385px;
	            }
	            @include desktop {
					width: 385px;
	            }
				.text {}
			}
		}
		.btn {
			margin: 0 auto;
		}
	}
}

.disease_info {
	margin-bottom: 155px;
	.container {
		position: relative;

		.heading {
			margin-bottom: 65px;
		}
		.disease_tabs {
			display: flex;
			justify-content: center;

			.disease_tabs_nav {
				width: 315px;
				.disease_nav_item {
					position: relative;
					display: flex;
					align-items: center;
                    flex-direction: column;
					width: unset;
					height: unset;
					margin: 15px 0;
					cursor: pointer;
					transition: all .5s ease;

                    @include tablet {
                    	flex-direction: column;
						width: unset;
						height: unset;
                    }
                    @include tablet-lg {
                    	flex-direction: row;
                    	width: 190px;
                    	height: 59px;
						margin: 0;
                    }
                    @include desktop {
                    	flex-direction: row;
                    	width: 190px;
                    	height: 59px;
						margin: 0;
                    }
					
                    &.active .nav_content {
                        height: 100%;
                    }
					&.active .title {
                        // font-weight: 500;
                        color: #000;
                    }

					.title {
                        position: relative;
						font-family: 'Roboto-Medium';
						font-size: 14px;
						line-height: 23px;
						text-transform: uppercase;
                        color: rgba(49, 49, 49, 0.5);
                        transition: all .3s ease;
                        cursor: pointer;

                        @include tablet {
							font-size: 14px;
                        }
                        @include tablet-lg {
							font-size: 18px;
                        }
                        @include desktop {
							font-size: 20px;
                        }

                        &:hover {
                            color: #000;
                        }
                        &::after {                            
                            content: url('../images/tab_arrow.svg');
                            position: absolute;
                            right: -18px;
                            width: 12px;
                            height: 6px;
                            opacity: 1;
                            transition: all .4s ease;

                            @include tablet {
                                opacity: 1;                                
                            }
                            @include tablet-lg {
                                opacity: 0;
                            }
                            @include desktop {
                                opacity: 0;
                            }
                        }                     
                    }
                    &.active .title::after {
                        transform: rotateZ(-90deg);
                        top: 8px;
                    }

					.nav_content {
                        display: flex;
                        flex-direction: column;
                        height: 0;
                        overflow: hidden;

                        p {
                            font-family: 'Roboto-Light';
                            font-size: 14px;
                            line-height: 16px;
                            color: #313131;
                            margin-top: 25px
                        }

                        @include tablet {
                            display: flex;
                        }
                        @include tablet-lg {
                            display: none;
                        }
                        @include desktop {
                            display: none;
                        }
                    }

					&:hover {
						color: #313131;
					}
				}
				.disease_nav_item span {
                    display: none;
					position: absolute;
					left: -110px;
					opacity: 0;
					font-family: 'Roboto-Black';
					font-size: 50px;
					line-height: 59px;
					color: #000000;
					transition: all .6s ease;
					@include tablet {
                        display: none;
                    }
                    @include tablet-lg {
                        display: flex;
                    }
                    @include desktop {
                        display: flex;
                    }		
				}
				.disease_nav_item.active span {
					left: -70px;
					opacity: 1;
				}
				.disease_nav_item:hover span {
					left: -70px;
					opacity: 1;
				}
			}
			.disease_tabs_content {
				display: none;
				position: relative;
				width: 500px;
				overflow: hidden;

				@include tablet {
                    display: none;
                }
                @include tablet-lg {
                    display: flex;
                }
                @include desktop {
                    display: flex;
                }
				.disease_content_item {
					position: absolute;
					right: -200px;
					width: 500px;
					opacity: 0;
					font-family: 'Roboto-Light';
					font-size: 14px;
					line-height: 16px;
					color: #313131;
					transition: all .6s ease;

					&.active {
						right: 0px;
						opacity: 1;
					}
				}
			}
		}
		#parallax_tabs_img_about {
			.info_tabs_img {
				position: absolute;
                z-index: -1;
			}
			.dots_img {
				left: -0px!important;
				top: 50px !important;			
            }
            .x_img {
				top: unset!important;
				left: unset!important;			
				right: -65%!important;
				bottom: 0!important;
            }
            .o_img {
				left: -200px!important;
				top: -370px!important;
            }
		}
	}
}

.about_symptoms {
	margin-bottom: 155px;

	.container {
		.heading {
			margin-bottom: 85px;
		}
		.symptoms_content {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			@include tablet {
				flex-wrap: wrap;
				justify-content: space-around;
            }
            @include tablet-lg {
				flex-wrap: wrap;
				justify-content: space-around;
            }
            @include desktop {
				flex-wrap: unset;				
				justify-content: space-between;
            }			

			.content_item {
				width: 235px;
				margin-bottom: 25px;
				.name {
					font-family: 'Roboto-Medium';
					font-size: 20px;
					line-height: 23px;
					text-transform: uppercase;
					color: #000000;
					margin-bottom: 20px;
				}
				.text {}
			}
		}
	}
}

.about_usage1 {
	margin-bottom: 105px;
	.container {
		.heading {
			margin-bottom: 35px;
		}
		.text {}
	}
}

.about_usage2 {
	position: relative;
	padding-bottom: 55px;
	
	@include tablet {
		padding-bottom: 155px;
    }
    @include tablet-lg {
		padding-bottom: 155px;
    }
    @include desktop {
		padding-bottom: 155px;
    }	


	.particles-js-canvas-el {
		position: absolute;
		top: 0;
		z-index: -1;
	}
	.container {
		.heading {
			margin-bottom: 60px;
		}
		.uasge2_content {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			@include tablet {
				flex-wrap: wrap;
				justify-content: space-around;
            }
            @include tablet-lg {
				flex-wrap: wrap;
				justify-content: space-around;
            }
            @include desktop {
				flex-wrap: unset;				
				justify-content: space-between;
            }

			.content_item {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 220px;
				height: 220px;
				border-radius: 100%;
				margin-bottom: 25px;
				background: radial-gradient(146.33% 146.43% at 7.65% 1.91%, #F7F7F8 0%, #FBFBFB 50.55%, #FFFFFF 71.51%);
				box-shadow: 3px 3px 8px rgba(0,0,0,0.3);
				transition: all 0.3s ease;

				&:hover {
					box-shadow: 7px 7px 12px rgba(0,0,0,0.3);
				}
				.text {
					width: 150px;
					text-align: center;
				}
			}
		}
	}
}