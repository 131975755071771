/* -------------------------------------------------------------------------- 
 * >>> TABLE OF UTILS : 
 * -------------------------------------------------------------------------- */

/* Utils */
/* -------------------------------------------------------------------------- */
@import 'utils/variables';
@import 'utils/functions';
@import 'utils/mixins';
@import 'utils/media-queries';
@import 'utils/fonts';
@import 'utils/reset';

/* Layout */
/* -------------------------------------------------------------------------- */
@import 'layout/site-header';
@import 'layout/site-footer';

/* Pages */
/* -------------------------------------------------------------------------- */
@import 'pages/page-about';
@import 'pages/page-instruction';
// @import 'pages/page-blog';
@import 'pages/page-test';

html,
body {
    width: 100%;
    height: 100%;
}
a {
    outline: 0!important;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.site_header,
.site_footer {
    flex-shrink: 0;
    // width: 100%;
}

.site-content {
    flex: 1 0 auto;
}


.container {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 540px;
    margin: auto;
    padding: 0 20px;
    box-sizing: border-box;

    @include tablet {
        max-width: 680px; 
        padding: 0 15px; 
    }
    @include tablet-lg {
        max-width: 960px;
        padding: 0 15px; 
    }
    @include laptop {
        max-width: 1225px; 
        padding: 0 15px;         
    }
    @include desktop {
        max-width: 1225px; 
        padding: 0 15px; 
    }
    // @include retina {
    //     max-width: 1225px;
    //     padding: 0 15px; 
    // }
}

.text {
    font-family: 'Roboto-Light';
    font-size: 14px;
    line-height: 16px;
    color: #313131;
}
.btn {
    display:flex;
    justify-content:center;
    align-items:center;
    width: 210px;
    height: 40px;
    background: url('../images/btn_bg.jpg') 0% 50% no-repeat;
    background-size: cover;
    // background:linear-gradient(135.63deg,  #AE3D6F 8.34%, #FF005C 57.97%, #AD396C 91.92%);
    border-radius: 10px;
    font-family: 'Roboto-Medium';
    font-size: 20px;
    line-height: 23px;
    text-transform: uppercase;
    text-decoration: none;
    color: #FFFFFF;
    cursor: pointer;
    transition: all .4s ease;

    &:hover {
        background: url('../images/btn_bg.jpg') 90% 50% no-repeat;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
        // background: linear-gradient(135.63deg, #FF005C 22.71%, #AE3D6F 52.31%, #FF005C 83.21%);
    }
}

.breadcrumbs {
    margin: 17px 0 45px;
    font-size: 14px;
    .container {
        flex-direction: row;
        a {
            text-decoration: none;
            color: #000;
            cursor: pointer;
        }
        span {
            // margin: 0 5px;
            cursor: pointer;
        }
    }
}

.heading {
    .light {
        font-family: 'Roboto-Light';
        font-size: 14px;
        line-height: 16px;
        color: #7D7D7D;
        text-align: center;

        @include tablet {
            font-size: 24px;
            line-height: 28px;
        }
        @include tablet-lg {
            font-size: 24px;
            line-height: 28px;
        }
        @include desktop {
            font-size: 24px;
            line-height: 28px;
        }
    }
    h2.h2 {
        font-family: 'Roboto-Medium';
        font-size: 20px;
        line-height: 23px;
        text-transform: uppercase;
        color: #313131;
        text-align: center;

        @include tablet {
            font-size: 38px;
            line-height: 45px;
        }
        @include tablet-lg {
            font-size: 38px;
            line-height: 45px;
        }
        @include desktop {
            font-size: 38px;
            line-height: 45px;
        }
    }
}

.first-screen {
    height: auto;
    overflow: hidden;
    margin-bottom: 35px;

    @include tablet {
        height: auto;
        margin-bottom: 35px;
    }
    @include tablet-lg {
        height: 700px;
        margin-bottom: 0px;
    }
    @include desktop {
        height: 700px;        
        margin-bottom: 0px;
    }
    .container {
        display: flex;
        justify-content: space-between;
        flex-direction: column-reverse;

        @include tablet {
            flex-direction: column-reverse;
        }
        @include tablet-lg {
            flex-direction: row;
        }
        @include desktop {
            flex-direction: row;
        }
        .left {
            .question {
                margin-top: 0px;

                @include tablet {
                    margin-top: 0px;
                }
                @include tablet-lg {
                    margin-top: 60px;                    

                }
                @include desktop {
                    margin-top: 60px;                    
                }

                .item {
                    font-family: 'Roboto-Regular';
                    font-size: 16px;
                    line-height: 19px;
                    color: #747474;
                    text-align: center;

                    @include tablet {
                        font-size: 32px;
                        line-height: 37px;
                        text-align: center;                        
                    }
                    @include tablet-lg {
                        font-size: 32px;
                        line-height: 37px;
                        text-align: start;
                    }
                    @include desktop {
                        font-size: 32px;
                        line-height: 37px;
                        text-align: start;                        
                    }
                }
            }
            .main_name {
                font-family: 'Roboto-Black';
                font-size: 50px;
                line-height: 59px;
                text-transform: uppercase;
                color: #A3295F;
                text-align: center;

                @include tablet {
                    font-size: 100px;
                    line-height: 117px;
                    text-align: center;  
                }
                @include tablet-lg {
                    font-size: 100px;
                    line-height: 117px;
                    text-align: start;
                }
                @include desktop {
                    font-size: 100px;
                    line-height: 117px;
                    text-align: start;
                }
            }
            .image {
                display: none;

                @include tablet {
                    display: none;
                }
                @include tablet-lg {
                    display: block;
                }
                @include desktop {
                    display: block;
                }
            }
            .btn {
                margin: 0px auto;

                @include tablet {
                    margin: 0px auto;

                }
                @include tablet-lg {
                    margin: 40px 0;
                }
                @include desktop {                    
                    margin: 40px 0;
                }
            }
        }
        .right {
            position: relative;
            display: flex;
            justify-content: center;

            .image {
                width: 300px;
                height: 285px;                    
                
                @include tablet {
                    width: 550px; 
                    height: unset;                   
                }
                @include tablet-lg {
                    width: 550px;
                    height: unset;                   
                }
                @include desktop {
                    width: unset;
                    height: unset;                   
                }
            }
            .bg_image {
                position: absolute;
                top: -80px;
                right: -300px;
            }
        }        
    }
}



@-webkit-keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg)
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
.information_tabs {
    margin-bottom: 80px;
    overflow: hidden;
    .container {
        flex-direction: column;
        
        .info_tabs {
            position: relative;
            height: 810px;
            @include tablet {
                height: 620px;
            }
            @include tablet-lg {
                height: 810px;
            }
            @include desktop {
                height: 810px;
            }

            .tabs_nav {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 40px;

                @include tablet {
                    flex-direction: column;
                }
                @include tablet-lg {
                    flex-direction: row;
                }
                @include desktop {
                    flex-direction: row;
                }

                .nav_item {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0 47px 20px;
                    flex-direction: column;

                    @include tablet {
                        flex-direction: column;
                    }
                    @include tablet-lg {
                        flex-direction: row;
                    }
                    @include desktop {
                        flex-direction: row;
                    }
                    
                    .nav_content {
                        display: flex;
                        flex-direction: column;
                        height: 0;
                        overflow: hidden;

                        img {
                            margin: 0 auto;
                        }
                        p {
                            font-family: 'Roboto-Light';
                            font-size: 14px;
                            line-height: 16px;
                            color: #313131;
                        }

                        @include tablet {
                            display: flex;
                        }
                        @include tablet-lg {
                            display: none;
                        }
                        @include desktop {
                            display: none;
                        }
                    }
                    &.active .nav_content {
                        height: 100%;
                    }

                    &.active .title {
                        // font-weight: 500;
                        color: #3D0047;
                    }
                    .title {
                        position: relative;
                        font-family: 'Roboto-Regular';
                        font-size: 16px;
                        line-height: 19px;
                        text-transform: uppercase;
                        color: rgba(49, 49, 49, 0.5);
                        transition: all .3s ease;
                        cursor: pointer;
                        &:hover {
                            color: #3D0047;
                        }
                        &::after {                            
                            content: url('../images/tab_arrow.svg');
                            position: absolute;
                            right: -18px;
                            width: 12px;
                            height: 6px;
                            opacity: 1;
                            transition: all .4s ease;

                            @include tablet {
                                opacity: 1;                                
                            }
                            @include tablet-lg {
                                opacity: 0;
                            }
                            @include desktop {
                                opacity: 0;
                            }
                        }                     
                    }
                    &.active .title::after {
                        transform: rotateZ(-90deg);
                        top: 8px;
                    }

                }
            }

            .tabs_content {
                display: none;
                justify-content: space-between;
                width: 100%;
                height: calc(100% - 80px);

                @include tablet {
                    display: none;
                }
                @include tablet-lg {
                    display: flex;
                }
                @include desktop {
                    display: flex;
                }

                .tabs_images {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50%;
                    z-index: -1;
                    .images_item {
                        // display: none;
                        position: absolute;
                        background: #7E7E7E;
                        width: 540px;
                        height: 540px;
                        box-sizing: border-box;
                        border: 23px solid #7E7E7E;
                        border-radius: 100%;
                        opacity: 0;
                        transition: all 1s ease;
                        &.active {
                            // display: block;
                            opacity: 1;
                        }
                    }
                    .circles {
                        position: absolute;
                    }
                    .circls1 {
                        animation: rotate 9.8s ease-in-out infinite;
                    }
                    .circls2 {
                        animation: rotate 12.8s ease-in-out alternate infinite;
                    }
                    .circls3 {
                        animation: rotate 14.8s linear alternate infinite;
                    }
                    .circls4 {
                        animation: rotate 10.8s ease-in-out alternate infinite;
                    }
                }
                .tabs_text {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 40%;

                    .text_item {
                        // display: none;
                        position: absolute;
                        right: -200px;
                        width: 490px;
                        opacity: 0;
                        transition: all .6s ease;
                        
                        @include tablet {
                        }
                        @include tablet-lg {
                            width: 330px;
                        }
                        @include desktop {
                            width: 490px;
                        }
                        .name {
                            margin-bottom: 30px;
                            font-family: 'Roboto-Medium';
                            font-size: 20px;
                            line-height: 23px;
                            text-transform: uppercase;
                        }
                        p {
                            font-family: 'Roboto-Light';
                            font-size: 14px;
                            line-height: 16px;
                            color: #313131;
                        }
                        &.active {
                            // display: block;
                            right: 0px;
                            opacity: 1;
                        }
                    }
                    #parallax_tabs_img {
                        position: absolute!important;
                        bottom: 0;
                        width: 100%;
                        .info_tabs_img {
                            position: absolute;
                            bottom: 0;
                            right: 0;
                            top: unset!important;
                            left: unset!important;
                        }
                        .dots_img {
                            right: 15%;
                            bottom: 5%;             
                        }
                        .x_img {
                            right: 10%;
                            bottom: 60px;           
                        }
                        .o_img {
                            right: -60%;
                        }
                    }
                }
            }
            
        }
    }
}

@-webkit-keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes dash {
    to {
        stroke-dashoffset: 0;
    }
}
.gel_description {
    position: relative;

    .particles-js-canvas-el {
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .container {
        .gel_info {
            display: flex;
            justify-content: space-around;
            align-items: center;
            height: auto;
            flex-direction: column;
            margin: 20px 0 40px;             

            @include tablet {
                height: auto;
                margin: 20px 0 40px;             
                flex-direction: column;
            }
            @include tablet-lg {
                height: 500px;
                flex-direction: row;
                margin: 0px;             
            }
            @include desktop {
                height: 500px;
                flex-direction: row;
                margin: 0px;             
            }
            .item {
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;               
                width: 315px;
                height: 290px;
                margin: 25px 0;                

                @include tablet {
                    margin: 15px 0;                
                }
                @include tablet-lg {
                    margin: 0;                
                }
                @include desktop {
                    margin: 0;                
                }
                svg {
                    position: absolute;
                    width: 315px;
                    height: 290px;
                    path {
                        // fill: #FF87BD;
                        fill: none;
                        stroke: #FF87BD;
                        stroke-width: 3;
                        stroke-dasharray: 600;
                        stroke-dashoffset: 1500;
                        -webkit-animation: dash 9s linear alternate infinite;
                        animation: dash 9s linear alternate infinite;
                        animation-delay: 0;
                    }
                }
                .decr_svg_1 {}
                .decr_svg_2 {}
                .decr_svg_3 {}
                .description {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    width: 180px;
                    height: 180px;
                    background: radial-gradient(146.33% 146.43% at 7.6% 1.91%, #F7F7F8 0%, #FBFBFB 50.55%, #FFFFFF 71.51%);
                    border-radius: 50%;
                    z-index: 1;
                    box-shadow: 30px 25px 5px rgba(0,0,0,0.3);
                    transition: all 0.6s ease;
                    &:hover {
                        box-shadow: 8px 6px 10px rgba(0,0,0,0.3);                        
                    }
                    .name {
                        font-family: 'Roboto-Bold';
                        font-size: 20px;
                        line-height: 23px;
                        text-align: center;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        color: #494C4F;
                    }
                    .text {
                        text-align: center;
                        color: #313131;
                    }
                }
            }
        }
    }
}

.gel_recipe {
    margin-bottom: 50px;
    position: relative;

    .particles-js-canvas-el {
        position: absolute;
        top: 0;
        z-index: -1;
    }
    .container {
        .gel_rec {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            @include tablet {
                flex-direction: column;
            }
            @include tablet-lg {
                flex-direction: row;
            }
            @include desktop {
                flex-direction: row;
            }

            .item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: auto;
                margin: 30px 0;

                @include tablet {
                    width: 100%;
                    height: auto;
                    margin: 30px 0;
                }
                @include tablet-lg {
                    width: 30%;
                    height: 480px;
                }
                @include desktop {
                    width: 30%;
                    height: 480px;
                }

                img {
                    width: 135px;
                    height: 135px;
                    transition: all .4s ease;
                }
                .image_gel {
                    display: none;
                    width: 330px;
                    height: 167px;
                    margin-top: 70px;

                    @include tablet {display: none;}
                    @include tablet-lg {display: block;}
                    @include tablet-lg {display: block;}
                }
                .name {
                    width: 70%;
                    font-family: 'Roboto-Light';
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    text-transform: uppercase;
                    margin-top: 15px;

                    @include tablet {
                        font-family: 'Roboto-Medium';
                        font-size: 20px;
                        line-height: 23px;
                    }
                    @include tablet-lg {
                        font-family: 'Roboto-Medium';
                        font-size: 20px;
                        line-height: 23px;
                    }
                    @include desktop {
                        font-family: 'Roboto-Medium';
                        font-size: 20px;
                        line-height: 23px;
                    }
                }
                &:hover .image {
                    transform: scale(1.3);
                }
            }
        }
        .gel_bottom {
            display: none;

            @include tablet {
                display: block;
            }
            @include tablet-lg {
                display: block;
            }
            @include desktop {
                display: block;
            }
            .description {
                font-family: 'Roboto-Regular';
                font-size: 24px;
                line-height: 28px;
                text-align: center;
                text-transform: uppercase;
            }
        }
    }
}

.gel_use_indications {
    margin-bottom: 70px;
    .container {
        .use_info {
            display: none;
            background: #7D7D7D;
            margin-top: 55px;

            @include tablet {
                display: block;
            }
            @include tablet-lg {
                display: block;
            }
            @include desktop {
                display: block;
            }
            .row {
                display: flex;
                width: 100%;

                @include tablet {
                    flex-direction: column;
                }
                @include tablet-lg {
                    flex-direction: column;
                }
                @include desktop {
                    flex-direction: row;
                }
                .item {
                    position: relative;
                    display: flex;
                    justify-content: space-between;
                    transition: all .5s ease;

                    @include tablet {
                        width: 100%;                        
                        height: 210px;
                    }
                    @include tablet-lg {
                        width: 100%;
                        height: 210px;
                    }
                    @include desktop {                        
                        width: 50%;
                        height: 210px;
                    }
                    .bg_img {
                        width: 50%;
                        transition: all .5s ease;
                    }
                    &:hover .bg_img {
                        width: 35%;
                    }
                    &:hover .center-number {
                        // opacity: 0;
                        // transition: all .5s ease;
                    }
                    .block-img1 {
                        background: url('../images/gel_use_indic1.png') no-repeat 50% 50%;
                        background-size: cover;
                    }
                    .block-img2 {
                        background: url('../images/gel_use_indic2.png') no-repeat 50% 50%;
                        background-size: cover;}
                    .block-img3 {
                        background: url('../images/gel_use_indic3.png') no-repeat 50% 50%;
                        background-size: cover;}
                    .block-img4 {
                        background: url('../images/gel_use_indic4.png') no-repeat 50% 50%;
                        background-size: cover;}
                    .item_block {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50%;
                        font-family: 'Roboto-Regular';
                        font-size: 20px;
                        line-height: 23px;
                        text-transform: uppercase;
                        color: #FFFFFF;
                    }
                    .center-number {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                    }
                }
                &.row_top .item_block {
                    padding-left: 10%;
                    box-sizing: border-box;
                }
                &.row_bottom .item_block {
                    padding-right: 10%;                    
                    box-sizing: border-box;
                }
            }
        }
        .user_info_mob {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 40px;

            @include tablet {display: none;}
            @include tablet-lg {display: none;}
            @include desktop {display: none;}

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 200px;
                margin-bottom: 25px;
                .use_number {
                    margin-bottom: 15px;
                }
                .text {
                    font-family: 'Roboto-Regular';
                    font-size: 16px;
                    line-height: 19px;
                    text-align: center;
                    color: #7D7D7D;
                }
            }
        }
    }
}

.useful_information {
    margin-bottom: 100px;
    .container {
        .btn {
            margin: 0 auto;
        }
    }
}


.swiper-container {
    width: 100%;
    box-sizing: border-box;
    padding: 10px 10px 40px;
    .news_block {
        // display: none;
        // justify-content: space-between;
        // position: relative;
        // margin: 50px 0 35px;

        // @include tablet {display: none;}
        // @include tablet-lg {display: flex;}
        // @include desktop {display: flex;}
        .swiper-slide{
            position: relative;
            // top: 0;
            height: 480px;
            background: #FFFFFF;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
            // transition: all .4s ease;
            text-decoration: none;
            &:hover {
                // top: -5px;
                box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
            }       
        }
    }
    .swiper-pagination {
        display: block;

        @include tablet {display: block;}
        @include tablet-lg {display: block;}
        @include desktop {display: none;}
    } 
    .swiper-pagination-bullet {
        width: 17px;
        height: 17px;
        background: #fff;
        border: 1px solid #C4C4C4;
        opacity: 1;
    }
    .swiper-pagination-bullet.swiper-pagination-bullet-active {
        background: #C4C4C4;
    }
}
a.post_item {
    // position: relative;
    // top: 0;
    width: 280px;
    height: 480px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    transition: all .4s ease;
    text-decoration: none;
    cursor: pointer;
    
    div,img {
        cursor: pointer;
    }
    &:hover {
        top: -5px;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    }
    .top {
        display: flex;
        width: 100%;
        height: 218px;
        img {
            width: 100%;
            height: 100%;
        }
    } 
    .name {
        height: 70px;
        padding: 0 15px;
        margin: 20px 0 10px;
        font-family: 'Roboto-Regular';
        font-size: 20px;
        line-height: 23px;
        color: #000000;
    }
    .text {                    
        padding: 0 15px;
        color: #000000;
    }
} 

.test#test {
    .container {
        .test_tabs {
            position: relative;
            width: 100%;
            height: 400px;
            // background: #000;
            .tabs_content {
                display: none;
                flex-direction: column;
                align-items: center;
                position: relative;
                margin-top: 30px;
                &.active {
                    display: flex;
                }
                .question {
                    max-width: 415px;
                    font-size: 20px;
                    line-height: 23px;
                    text-align: center;
                    color: #494C4F;
                    margin-bottom: 30px;
                }
                .buttons {
                    display: flex;
                    .test_btn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 70px;
                        height: 40px;
                        border: 2px solid #000000;
                        box-sizing: border-box;
                        border-radius: 5px;
                        font-family: 'Roboto-Medium';
                        font-size: 20px;
                        line-height: 23px;
                        text-align: center;
                        text-transform: uppercase;
                        cursor: pointer;
                    }
                }
            }
            .circles {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                overflow: hidden;
                transform: rotateX(60deg);


                @include tablet {
                    overflow: visible;
                }
                @include tablet-lg {
                    overflow: visible;
                }
                @include desktop {
                    overflow: visible;
                }
                .test_circle {
                    position: absolute;
                    width: 100%;

                    @include tablet {
                        width: unset;
                    }
                    @include tablet-lg {
                        width: unset;
                    }
                    @include desktop {
                        width: unset;
                    }
                }                
                .circle1 {
                    animation: rotate 9.8s ease-in-out infinite;
                }
                .circle2 {
                    animation: rotate 12.8s ease-in-out alternate infinite;
                }
                .circle3 {
                    animation: rotate 14.8s linear alternate infinite;
                }
                .circle4 {
                    animation: rotate 10.8s ease-in-out alternate infinite;
                }
                .circle5 {
                    animation: rotate 13.8s ease infinite;
                }
                .vector {
                    position: absolute;
                }
                .vector1 {
                    top: 70px;
                }
                .vector2 {
                    top: -50px;
                }
            }            
        }
    }
}

.all_blogs {
    margin-bottom: 120px;
    .container {
        .heading {
            margin-bottom: 65px;
        }
        .blogs_wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @include tablet {
            justify-content: space-between;
            }

            @include tablet-lg {
            justify-content: space-around;
            }
            @include desktop {
            justify-content: space-between;
            }

            a.post_item {
                position: relative;
                top: 0;
                width: 280px;
                height: 480px;
                background: #FFFFFF;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
                margin-bottom: 30px;
                transition: all .4s ease;
                text-decoration: none;
                cursor: pointer;

                &:hover {
                    top: -5px;
                    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
                }
                .top {
                    display: flex;
                    width: 100%;
                    height: 218px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                } 
                .name {
                    height: 70px;
                    padding: 0 20px;
                    margin: 20px 0 10px;
                    font-family: 'Roboto-Regular';
                    font-size: 20px;
                    line-height: 23px;
                    color: #000000;
                }
                .text {                    
                    padding: 0 20px;
                    color: #000000;
                }
            }  
        }
    }
}

section.article {
    .article_header {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        @include tablet {
            flex-direction: row;
        }

        .image {
            margin-right: 0px;
            margin: 0 auto;
            margin-bottom: 20px;

            @include tablet {
                margin-right: 25px;
                margin-bottom: 0px;
            }
        }
    }

    .paragraph_row {
        .paragraph_content {
            display: flex;
            justify-content: space-between;
            flex-direction: column;

            @include tablet {
                flex-direction: row;
            }

            .paragraph_content-item {
                width: 100%;

                @include tablet {
                    width: 47%;
                }
            }
        }
    }
    
        .text {
            margin-bottom: 15px;

            span {
                font-family: 'Roboto-Regular';                
            }
        }
    
    .article_heading {
        font-family: 'Roboto-Medium';
        font-size: 20px;
        line-height: 130%;
        text-transform: uppercase;
        color: #313131;
        margin-bottom: 20px;

        @include tablet {
            font-size: 38px;
        }
    }
    .paragraph_heading {
        font-family: 'Roboto-Medium';
        font-size: 16px;
        line-height: 130%;
        color: #000000;
        margin-bottom: 15px;

        @include tablet {
            font-size: 20px;
        }
    }
}

.terms_of_use {
    margin: 30px 0;

    .chapter {
        font-family: 'Roboto-Light';
        font-size: 14px;        
        line-height: 16px;        
        color: #313131
    }
}

.privacy_policy {
    margin: 30px 0;

    .chapter {
        margin: 10px 0;
        h3 {
            font-family: 'Roboto-Bold';
            font-size: 16px;
            line-height: 19px;
            color: #313131;
        }
        p {
            font-family: 'Roboto-Light';
            font-size: 14px;
            line-height: 16px;
            color: #313131;
        }
    }
}