/* -------------------------------------------------------------------------- 
 * Fonts weights :
 * --------------------------------------------------------------------------
 * 100: Thin (Hairline)
 * 200: Extra Light (Ultra Light)
 * 300: Light
 * 400: Regular
 * 500: Medium
 * 600: Semi Bold
 * 700: Bold
 * 800: Extra Bold (Ultra Bold)
 * 900: Black (Heavy)
 * -------------------------------------------------------------------------- */

/* Roboto */
/* -------------------------------------------------------------------------- */

// Roboto 300: Light
@include font-face(Roboto-Light, Roboto/Roboto-Light, 300, normal, eot woff2 woff ttf svg);

// Roboto 400: Regular
@include font-face(Roboto-Regular, Roboto/Roboto-Regular, 400, normal, eot woff2 woff ttf svg);

// Roboto 500: Medium
@include font-face(Roboto-Medium, Roboto/Roboto-Medium, 500, normal, eot woff2 woff ttf svg);

// Roboto 600: Semi Bold
// @include font-face(Roboto, Roboto/Roboto-SemiBold, 600, normal, eot woff2 woff ttf svg);

// Roboto 700: Bold
@include font-face(Roboto-Bold, Roboto/Roboto-Bold, 700, normal, eot woff2 woff ttf svg);

// Roboto 800: Extra Bold (Ultra Bold)
// @include font-face(Roboto, Roboto/Roboto-Bold, 800, normal, eot woff2 woff ttf svg);

// Roboto 900: Black (Heavy)
@include font-face(Roboto-Black, Roboto/Roboto-Black, 900, normal, eot woff2 woff ttf svg);
